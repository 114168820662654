import { useState } from "react";
import { Product } from "../types/graphql";
import MediaImage from "./MediaImage";

type ProductItemProps = {
  product: Product;
};

const ProductItem = ({ product }: ProductItemProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div key={product.id} className="flex items-center gap-6 w-full py-4">
      <div className="flex-shrink-0 w-16">
        <MediaImage
          media={product.media}
          loading={loading}
          onLoad={() => setLoading(false)}
        />
      </div>
      <div className="flex-1 flex flex-col">
        <span className="block text-xl font-semibold text-white/80">
          {product.name}
        </span>
        <span className="block text-sm text-white/50">
          {product.description}
        </span>
      </div>
      <div>
        <span className="block text-base text-white/70">{product.price}:-</span>
      </div>
    </div>
  );
};

export default ProductItem;
