import Layout from "../components/Layout";
import { useDictionary } from "../context/dictionary";
import { useProducts } from "../context/products";

const Contact = (): JSX.Element => {
  const { dictionary } = useDictionary();
  const { sections, loading, error } = useProducts();

  return (
    <Layout>
      <div className="w-full max-w-6xl mx-auto flex gap-16 py-10 [&>div]:w-2/4">
        <div className="max-lg:p-9">
          <div className="flex flex-col flex-wrap">
            <div className="mb-6">
              <h2 className="text-3xl font-semibold text-white">
                {dictionary.MAIN_ABOUT_TITLE}
              </h2>
            </div>
            <div className="[&>p]:mb-5 [&>p]:text-white/75 [&>p]:italic">
              {dictionary.MAIN_ABOUT_TEXT.map((text, index) => (
                <p key={`about-${index}`}>{text}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <img src="/images/logo.png" alt="" className="w-full" />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
