import Layout from "../components/Layout";

const NotFound = (): JSX.Element => {
  return (
    <Layout>
      <span>404 Not found</span>
    </Layout>
  );
};

export default NotFound;
