import { ApolloError } from "@apollo/client";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AccessoryType } from "../components/TextInput/Accessory";
import { TextInput } from "../components/TextInput/TextInput";

// gql`
//   mutation Login($password: String!) {
//     login(passsword: $password) {
//       token
//     }
//   }
// `;

const schema = yup.object().shape({
  password: yup.string().required("Password cannot be empty."),
});

type FormFields = yup.InferType<typeof schema>;

const Login = (): JSX.Element => {
  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    criteriaMode: "all",
    resolver: yupResolver(schema),
  });

  const handleLogin = async ({ password }: FormFields) => {
    try {
      // clearAccessToken();
      // const { data } = await login({ variables: { password } });
      // const token = unwrap(data?.login?.token);
      // setAccessToken(token);

      window.location.href = "/";
    } catch (err) {
      form.setError("password", {
        message:
          err instanceof ApolloError ? err.message : "Something went wrong.",
      });
    }
  };

  return (
    <main className="relative isolate min-h-full">
      <img
        alt=""
        src="/images/tropical-food-truck.png"
        className="absolute inset-0 -z-10 size-full object-cover object-top brightness-[0.2]"
      />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="text-base/8 font-semibold text-white">503</p>
        <h1 className="mt-3 text-5xl font-semibold text-white sm:text-6xl">
          Site is under Contruction
        </h1>
        <p className="mt-6 text-pretty text-lg font-medium text-white/70 sm:text-xl/8">
          We're working hard to improve the user experience. Stay tuned!
        </p>
        <div className="mx-auto mt-10 w-full max-w-2xl">
          <form onSubmit={form.handleSubmit(handleLogin)}>
            <TextInput
              key="password"
              form={form}
              name="password"
              title="Enter password to get access"
              accessory={{
                type: AccessoryType.Button,
                icon: <LockClosedIcon />,
                onPress: form.handleSubmit(handleLogin),
              }}
            />
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
