import clsx from "clsx";
import { useState } from "react";
import { MEDIA_API_ENDPOINT } from "../config";
import { Media } from "../types/graphql";

type MediaImageProps = {
  media?: Media;
  alt?: string;
  aspectRatio?: `${number}:${number}`;
  loading?: boolean;
  onLoad?: () => void;
};

const MediaImage = ({
  media,
  alt,
  aspectRatio = "1:1",
  loading = false,
  onLoad,
}: MediaImageProps): JSX.Element => {
  const [error, setError] = useState<boolean>(false);

  const [arx, ary] = aspectRatio.split(":").map((item) => parseInt(item));
  const src = media ? MEDIA_API_ENDPOINT + "/" + media.id : undefined;

  return (
    <div
      className="relative overflow-hidden"
      style={{ width: "100%", paddingBottom: `calc(100% / ${arx} * ${ary})` }}
    >
      {!error ? null : (
        <div className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center">
          <span>Couldn't load image</span>
        </div>
      )}

      {!loading ? null : (
        <div className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center animate-pulse bg-gray-200"></div>
      )}

      <img
        src={src}
        alt={alt}
        onLoad={onLoad}
        onError={() => {
          onLoad?.();
          setError(true);
        }}
        className={clsx(
          "absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full object-cover transition-opacity ease-out duration-200",
          loading || error ? "opacity-0 pointer-events-none" : "opacity-1",
        )}
      />
    </div>
  );
};

export default MediaImage;
