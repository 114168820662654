import { ApolloError, gql } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";
import { Product, useListProductsQuery } from "../types/graphql";

gql`
  query ListProducts {
    products {
      id
      name
      description
      category
      media {
        id
      }
    }
  }
`;

export type SectionItem = {
  title: string;
  products: Product[];
};

type ProductsContextReturn = {
  data: Product[] | undefined;
  sections: SectionItem[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const ProductsContext = createContext<ProductsContextReturn | null>(null);

export const ProductsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, loading, error } = useListProductsQuery();

  const sections = useMemo(() => {
    if (!data) return undefined;
    const sections: SectionItem[] = [];

    for (const product of data.products) {
      let section = sections.find((item) => item.title === product.category);

      if (!section) {
        section = { title: product.category, products: [] };
        sections.push(section);
      }

      section.products.push(product);
    }
    return sections;
  }, [data]);

  return (
    <ProductsContext.Provider
      value={{ data: data?.products, sections, loading, error }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductsProvider");
  }
  return context;
};
