import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useRef, useState } from "react";
import { useDictionary } from "../context/dictionary";

const Dropdown = (): JSX.Element => {
  const { dictionary } = useDictionary();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);

    if (dropdownRef.current) {
      if (!isOpen) {
        const scrollHeight = dropdownRef.current.scrollHeight;
        dropdownRef.current.style.height = `${scrollHeight}px`;
      } else {
        dropdownRef.current.style.height = `0px`;
      }
    }
  };

  return (
    <div className="border-b border-white/75">
      <button
        onClick={toggleDropdown}
        className="w-full flex items-center justify-start gap-2 px-2 py-4"
      >
        <ChevronRightIcon
          className={`w-6 text-primary transition-transform ${
            isOpen ? "rotate-90" : "rotate-0"
          }`}
        />
        <span className="text-xl text-primary">
          {dictionary.MAIN_FAQ_BUTTON_TITLE}
        </span>
      </button>

      <div
        className="overflow-hidden transition-height duration-500 ease-in-out"
        ref={dropdownRef}
        style={{ height: "0px" }}
      >
        <div className="p-4 [&>p]:mb-6 [&>p]:text-lg [&>p]:text-white">
          {dictionary.MAIN_FAQ_DRODOWN_TEXT.map((text, index) => (
            <p key={`dropdown-${index}`}>{text}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
