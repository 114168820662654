import { gql } from "@apollo/client";
import Dropdown from "../components/Dropdown";
import Layout from "../components/Layout";
import ProductList from "../components/ProductList";
import { useDictionary } from "../context/dictionary";
import { useProducts } from "../context/products";

gql`
  query ListProducts {
    products {
      id
      name
      description
      category
      media {
        id
      }
    }
  }
`;

const LandingPage = (): JSX.Element => {
  const { dictionary } = useDictionary();
  const { sections, loading, error } = useProducts();

  return (
    <Layout>
      <div className="w-full">
        <img src="/images/banner.jpg" alt="Banner" className="w-full" />
      </div>

      <div className="w-full max-w-6xl mx-auto flex gap-16 py-10 [&>div]:w-2/4">
        <div className="flex flex-col">
          <div className="mb-10">
            <h2 className="font-russo-one text-2xl text-center tracking-widest text-white">
              {dictionary.MAIN_WELCOME}
            </h2>
          </div>
          <img
            src="/images/tacos.png"
            alt="Tacos"
            className="w-full color-filter"
          />
        </div>
        <div className="max-lg:p-9">
          <div className="flex flex-col flex-wrap">
            <div className="mb-6">
              <h2 className="text-3xl text-white">
                {dictionary.MAIN_FAQ_TITLE}
              </h2>
            </div>
            <div className="mb-12">
              <p className="text-lg text-white">{dictionary.MAIN_FAQ_TEXT}</p>
            </div>
            <div className="mb-6">
              <Dropdown />
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden w-full flex flex-col py-12">
        <div className="z-20 w-full max-w-2xl md:max-w-3xl lg:max-w-4xl mx-auto p-12">
          <h2 className="mb-6 text-center font-rock-salt text-2xl text-white/90">
            {dictionary.MAIN_MENU_TITLE}
          </h2>
          <ProductList sections={sections ?? []} />
        </div>
        <div className="absolute top-0 left-0 bottom-0 right-0 z-10 w-full h-full pointer-events-none bg-white invert">
          <img
            src="/images/menu-texture.jpg"
            alt=""
            className="w-full h-full absolute top-0 left-0 opacity-75 object-cover"
          />
        </div>
      </div>

      <div className="w-full max-w-6xl mx-auto flex gap-16 py-10 [&>div]:w-2/4">
        <div className="max-lg:p-9">
          <div className="flex flex-col flex-wrap">
            <div className="mb-6">
              <h2 className="text-3xl font-semibold text-white">
                {dictionary.MAIN_ABOUT_TITLE}
              </h2>
            </div>
            <div className="[&>p]:mb-5 [&>p]:text-white/75 [&>p]:italic">
              {dictionary.MAIN_ABOUT_TEXT.map((text, index) => (
                <p key={`about-${index}`}>{text}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <img src="/images/logo.png" alt="" className="w-full" />
        </div>
      </div>
    </Layout>
  );
};

export default LandingPage;
