import { createContext, useContext, useEffect, useState } from "react";
import en from "../locales/en.json";
import es from "../locales/es.json";
import se from "../locales/se.json";

const SUPPORTED_LANGUAGES = ["se", "es", "en"] as const;
const DEFAULT_LANGUAGE = "se" as const;

type Language = (typeof SUPPORTED_LANGUAGES)[number];
export type Dictionary = typeof se;

const isSupportedLanguage = (lang: any): lang is Language => {
  return SUPPORTED_LANGUAGES.includes(lang);
};

const getLangStorage = (): Language => {
  const storedLang = localStorage.getItem("lang");
  if (storedLang === null || !isSupportedLanguage(storedLang)) {
    return DEFAULT_LANGUAGE;
  }
  return storedLang;
};

// Preloaded dictionaries
const dictionaries: Record<Language, Dictionary> = { en, es, se };

type DictionaryContextReturn = {
  dictionary: Dictionary;
  lang: Language;
  setLang: (lang: string) => void;
};

const DictionaryContext = createContext<DictionaryContextReturn | null>(null);

export const DictionaryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [lang, _setLang] = useState<Language>(getLangStorage);
  const [dictionary, setDictionary] = useState<Dictionary>(dictionaries[lang]);

  const setLang = (newLang: string) => {
    if (!isSupportedLanguage(newLang)) return;
    _setLang(newLang);
    setDictionary(dictionaries[newLang]);
  };

  useEffect(() => {
    localStorage.setItem("lang", lang);
  }, [lang]);

  return (
    <DictionaryContext.Provider value={{ dictionary, lang, setLang }}>
      {children}
    </DictionaryContext.Provider>
  );
};

export const useDictionary = () => {
  const context = useContext(DictionaryContext);
  if (!context) {
    throw new Error("useDictionary must be used within a DictionaryProvider");
  }
  return context;
};
