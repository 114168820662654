import React from "react";
import ReactDOM from "react-dom/client";
import { DictionaryProvider } from "./context/dictionary";
import Routes from "./routes";

import { ApolloProvider } from "@apollo/client";
import "./assets/index.css";
import client from "./clients/graphql";
import { ProductsProvider } from "./context/products";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <DictionaryProvider>
        <ProductsProvider>
          <Routes />
        </ProductsProvider>
      </DictionaryProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
