import Layout from "../components/Layout";
import ProductList from "../components/ProductList";
import { useDictionary } from "../context/dictionary";
import { useProducts } from "../context/products";

const Menu = (): JSX.Element => {
  const { dictionary } = useDictionary();
  const { sections, loading, error } = useProducts();

  return (
    <Layout>
      <div className="relative overflow-hidden w-full flex flex-col py-12">
        <div className="z-20 w-full max-w-2xl md:max-w-3xl lg:max-w-4xl mx-auto p-12">
          <h2 className="mb-6 text-center font-rock-salt text-2xl text-white/90">
            {dictionary.MAIN_MENU_TITLE}
          </h2>
          <ProductList sections={sections ?? []} />
        </div>
        <div className="absolute top-0 left-0 bottom-0 right-0 z-10 w-full h-full pointer-events-none bg-white invert">
          <img
            src="/images/menu-texture.jpg"
            alt=""
            className="w-full h-full absolute top-0 left-0 opacity-75 object-cover"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Menu;
