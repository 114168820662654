import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GRAPHQL_API_ENDPOINT } from "../config";

const httpLink = createHttpLink({
  uri: GRAPHQL_API_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
  
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, httpLink]),
  defaultOptions: {
    mutate: {
      errorPolicy: "none",
    },
  },
});

export default client;