import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useDictionary } from "../context/dictionary";

const languages = ["se", "es", "en"];

const LanguageSelect = (): JSX.Element => {
  const { lang, setLang } = useDictionary();

  return (
    <Listbox value={lang} onChange={setLang}>
      <div className="relative">
        <ListboxButton className="relative w-full min-w-20 cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-0 sm:text-sm/6">
          <span className="block truncate uppercase">{lang}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {languages.map((item) => (
            <ListboxOption
              key={item}
              value={item}
              className="group relative flex items-center cursor-default select-none py-2 px-3 text-gray-900 data-[focus]:bg-sky-500 data-[focus]:text-white"
            >
              <span className="flex-1 block truncate font-normal group-data-[selected]:font-semibold uppercase">
                {item}
              </span>

              <span className="flex-shrink-0 text-sky-500 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-4 w-4" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default LanguageSelect;
