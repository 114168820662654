import { SectionItem } from "../context/products";
import ProductItem from "./ProductItem";

type ProductListProps = {
  sections: SectionItem[];
};

const ProductList = ({ sections }: ProductListProps): JSX.Element => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-12">
      {sections.map((section, index) => (
        <div
          key={`section-${index}`}
          className="flex flex-col py-2 sm:col-span-5 sm:odd:col-start-1 sm:even:col-start-8"
        >
          <div className="py-2">
            <span className="block text-center text-lg font-semibold text-white/90">
              {section.title}
            </span>
          </div>
          <div className="flex flex-col">
            {section.products.map((product) => (
              <ProductItem key={product?.id} product={product} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
