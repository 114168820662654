import clsx from "clsx";
import React from "react";
import { ContextIcon } from "../Icon";

export enum AccessoryType {
  Icon,
  Context,
  Button,
}

export type AccessoryProps =
  | {
      type: AccessoryType.Icon;
      icon: React.ReactNode;
      description?: never;
      onPress?: never;
    }
  | {
      type: AccessoryType.Context;
      icon?: React.ReactNode;
      description: string;
      onPress?: never;
    }
  | {
      type: AccessoryType.Button;
      icon: React.ReactNode;
      description?: never;
      onPress: () => void;
    };

export const Accessory = ({
  type,
  icon = <ContextIcon />,
  description,
  onPress,
}: AccessoryProps): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);

  switch (type) {
    case AccessoryType.Icon: {
      return (
        <div className="absolute top-0 right-0 bottom-0 flex items-center justify-center pr-[11px] pointer-events-none">
          <span className="w-[20px] h-[20px] [&_svg]:w-full [&_svg]:h-full [&_svg]:text-gray-500">
            {icon}
          </span>
        </div>
      );
    }
    case AccessoryType.Context: {
      return (
        <div className="relative flex items-center justify-center">
          <span
            className="flex p-[11px] cursor-pointer"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <span className="pointer-events-none w-[20px] h-[20px] [&_svg]:w-full [&_svg]:h-full [&_svg]:text-gray-500">
              {icon}
            </span>
          </span>
          <div
            className={clsx(
              "absolute bottom-full w-[154px] pointer-events-none select-none transition-[opacity,transform] duration-200 ease-linear origin-bottom",
              open ? "opacity-100 scale-100" : "opacity-0 scale-90",
            )}
          >
            <div className="p-[11px] rounded-md bg-black/90">
              <span className="flex text-center text-xs leading-[18px] font-helvetica-neue text-white">
                {description}
              </span>
            </div>
            <span className="absolute left-2/4 -translate-x-2/4 border-[5px] border-b-0 border-transparent border-t-black/90"></span>
          </div>
        </div>
      );
    }
    case AccessoryType.Button: {
      return (
        <div className="flex items-center justify-center">
          <button
            type="button"
            onClick={onPress}
            className="flex p-[11px] [&_svg]:text-gray-500 hover:[&_svg]:text-gray-700 [&_svg]:transition-colors [&_svg]:duration-100 [&_svg]:ease-linear"
          >
            <span className="pointer-events-none w-[20px] h-[20px] [&_svg]:w-full [&_svg]:h-full">
              {icon}
            </span>
          </button>
        </div>
      );
    }
  }
};
