import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Contact from "./views/Contact";
import LandingPage from "./views/LandingPage";
import Login from "./views/Login";
import Menu from "./views/Menu";
import NotFound from "./views/NotFound";

const Routes = () => {
  const routesForPublic = [
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <NotFound />,
    },
    {
      path: "/menu",
      element: <Menu />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/login",
      element: <Login />,
    },
  ];

  const router = createBrowserRouter([...routesForPublic]);

  return <RouterProvider router={router} />;
};

export default Routes;
