import { ReactNode } from "react";
import { useDictionary } from "../context/dictionary";
import { FacebookIcon, InstagramIcon } from "./Icon";
import Link from "./Link";
import LanguageSelect from "./Select";

type LayoutProps = {
  children: ReactNode;
};

const footerLinks = [
  {
    name: "TropicalFoodtruck LatinStreetfood",
    icon: <FacebookIcon />,
    href: "https://www.facebook.com",
  },
  {
    name: "TropiFoodTruck",
    icon: <InstagramIcon />,
    href: "https://www.instagram.com",
  },
];

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const { dictionary } = useDictionary();

  const navigation = [
    { title: dictionary.NAVIGATION_HOME, href: "/" },
    { title: dictionary.NAVIGATION_MENU, href: "/menu" },
    { title: dictionary.NAVIGATION_CONTACT, href: "/contact" },
  ];

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 w-full z-50 shadow-md bg-secondary">
        <div className="w-full max-w-5xl mx-auto py-3 max-lg:p-5">
          <div className="flex items-center">
            <div className="flex-1">
              <div className="h-10 cursor-pointer">
                <img
                  src="/images/logo.png"
                  alt="Tropical Food Truck"
                  className="h-full"
                />
              </div>
            </div>

            <ul className="flex items-center">
              {navigation.map((item) => (
                <li key={item.href} className="group">
                  <Link
                    href={item.href}
                    className="flex flex-col cursor-pointer"
                  >
                    <div className="px-6 py-3">
                      <span className="text-base text-white">{item.title}</span>
                    </div>
                    <span className="block w-full h-0.5 group-hover:bg-primary transition-colors"></span>
                  </Link>
                </li>
              ))}
            </ul>

            <div className="ml-4">
              <div className="px-4 py-2 bg-primary rounded-lg cursor-pointer">
                <div className="flex items-center gap-2">
                  <span className="text-base/4 text-white">WHATSAPP</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-4 fill-white"
                  >
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="ml-4">
              <LanguageSelect />
            </div>
          </div>
        </div>
      </nav>

      <main className="relative flex min-h-screen flex-col items-center justify-center pt-[74px]">
        {children}
      </main>

      <footer className="relative overflow-hidden">
        <div className="relative z-20 w-full max-w-5xl mx-auto py-8">
          <div className="flex [&_li]:text-sm [&_li]:text-white/75 [&_h2]:text-base [&_h2]:text-white">
            <div className="flex flex-col p-7">
              <h2 className="mb-2">DIRECCIÓN</h2>
              <ul className="flex flex-col">
                <li>Alva Myrdals Gata 3, 129 57 Hägersten</li>
                <li>+46 70 416 68 94</li>
                <li>tropicaltruck1@gmail.com</li>
              </ul>
            </div>

            <div className="flex flex-col p-7">
              <h2 className="mb-2">REDES SOCIALES</h2>
              <ul className="flex flex-col">
                {footerLinks.map((item, index) => (
                  <li key={`footer-link-${index}`}>
                    <a
                      href={item.href}
                      className="flex items-center gap-x-2 py-2"
                    >
                      <div className="[&>svg]:w-6 text-white/50">
                        {item.icon}
                      </div>
                      <span>{item.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col p-7">
              <h2 className="mb-2">LO QUE HACEMOS</h2>
              <ul className="flex flex-col">
                <li>Tacos</li>
                <li>Ceviches</li>
                <li>Arepas</li>
                <li>Sandwich</li>
                <li>Hamburguesa</li>
              </ul>
            </div>

            <div className="flex-1 p-7">
              <img src="/images/logo.png" alt="" className="w-full" />
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full pointer-events-none z-10 bg-black">
          <img
            src="/images/tropical-food-truck.png"
            alt=""
            className="w-full top-2/4 -translate-y-1/3 opacity-25"
          />
        </div>
      </footer>
    </>
  );
};

export default Layout;
