import clsx from "clsx";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { Accessory, AccessoryProps, AccessoryType } from "./Accessory";

type TextInputType = "text" | "password";

type TextInputProps<TFieldValues extends FieldValues> = {
  accessory?: AccessoryProps;
  autoComplete?: string;
  autofocus?: true;
  form: UseFormReturn<TFieldValues>;
  maxLength?: number;
  name: Path<TFieldValues>;
  title: string;
  type?: TextInputType;
  formater?: (value: string) => string;
};

export const TextInput = <T extends FieldValues>({
  accessory,
  autoComplete = "off",
  autofocus,
  form,
  maxLength,
  name,
  title,
  type = "text",
  formater,
}: TextInputProps<T>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({
        field: { onChange, onBlur, ref, value = "" },
        fieldState: { error },
      }) => {
        const notEmpty = value.trim() !== "";
        return (
          <div className="flex flex-col gap-y-1">
            <div className="relative">
              <div
                className={clsx(
                  "absolute top-[7px] left-[12px] flex",
                  "pointer-events-none select-none",
                  "transition-[opacity,transform] duration-200 ease-linear",
                  notEmpty ? "opacity-100" : "opacity-0",
                  notEmpty ? "translate-y-0" : "translate-y-1/3",
                )}
              >
                <span className="text-xs text-gray-500">{title}</span>
              </div>

              <div
                className={clsx(
                  "flex items-center rounded-md",
                  "bg-white border border-gray-300",
                  "transition-[border-color,box-shadow] duration-200 ease-in-out",
                  {
                    "[&:has(:focus-visible)]:border-primary-600 [&:has(:focus-visible)]:shadow-focus-outer":
                      !error,
                  },
                  !error
                    ? "border-gray-300"
                    : "border-red-600 shadow-invalid-outer",
                )}
              >
                <input
                  type={type}
                  name={name}
                  id={name}
                  ref={ref}
                  value={value}
                  placeholder={title}
                  onChange={(e) =>
                    onChange(
                      formater ? formater(e.target.value) : e.target.value,
                    )
                  }
                  onBlur={() => onBlur()}
                  autoCapitalize="off"
                  autoComplete={autoComplete}
                  autoCorrect="false"
                  autoFocus={autofocus ?? false}
                  maxLength={maxLength}
                  spellCheck="false"
                  className={clsx([
                    "appearance-none border-none focus:ring-0",
                    "rounded-md",
                    "text-gray-900 placeholder:text-gray-500",
                    "transition-[padding] duration-200 ease-in-out",
                    "flex-1 pl-[11px]",
                    notEmpty ? "pb-[6px] pt-[21px]" : "py-[13.5px]",
                    accessory?.type === AccessoryType.Icon
                      ? "pr-[42px]"
                      : "pr-[11px]",
                  ])}
                />

                {!accessory ? null : <Accessory {...accessory} />}
              </div>
            </div>

            {!error ? null : (
              <span className="block text-sm/6 text-red-700">
                {error.message}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export const PasswordInput = <T extends FieldValues>({
  type,
  autoComplete = "new-password",
  ...props
}: TextInputProps<T>): JSX.Element => {
  return <TextInput type="password" autoComplete={autoComplete} {...props} />;
};
